$color-green-light: #a1c618;
$color-green-dark: #B6C92F;

$color-grey-light: var(--bs-gray);
$color-grey-dark: var(--bs-gray-900);

$main-menu-text-color: var(--bs-white);
$main-menu-text-hover-color: $color-grey-light;
$main-menu-text-active-color: $color-grey-dark;

$table-header-color: $color-grey-dark;
$table-header-bg-color: $color-green-light;