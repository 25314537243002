@import '../variables';


#app-sidebar {
    min-width: 200px;
    max-width: 200px;
    background: $color-green-light;
    color: $main-menu-text-color;
    transition: all 0.3s;
    &.active {
        min-width: 60px;
        max-width: 60px;
        text-align: center;
        .nav a {
            padding: 10px !important;
            padding: 20px 10px;
            text-align: center;
            font-size: 0.85em;
            i {
                margin-right: 0;
                display: block;
                font-size: 1.8em;
                margin-bottom: 5px;
            }
        }
    }
    .nav a {
        padding: 10px;
        font-size: 1.1em;
        display: block;
        color: $main-menu-text-color;
        text-align: left;
        &:hover,
        &:focus {
            transition: all 0.3s;
            color: $main-menu-text-hover-color;
        }
        &.active{
            color: $main-menu-text-active-color;

        }
        i {
            margin-right: 10px;
        }
    }
    .sidebar-header {
        padding: 3px;
        background: $color-green-light;
        .btn-link {
            color: $color-grey-dark;
        }
    }
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 768px) {
    #app-sidebar {
        min-width: 80px;
        max-width: 80px;
        text-align: center;
        margin-left: -80px !important;
    }
    #app-sidebar.active {
        margin-left: 0 !important;
    }
}