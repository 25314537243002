@import '../variables';

:root {
  --bs-body-color: #777;
  --bs-body-bg:#f3f3f3;
}

.table th {
  background-color: $table-header-bg-color;
  color: $table-header-color;
}

.badge.bg-salt {
  background-color: $color-green-light;
  color: $color-grey-dark;
}

.page-item.active .page-link {
  background-color: $color-green-light;
  border-color: $color-green-light;
}
.page-link {
  color: $color-green-dark;
}

.page-link:focus {
  color: $color-green-light;
  background-color: #e9ecef;
  box-shadow: none;
}

.page-link:hover {
  color: $color-grey-dark;
}

.carousel-dark .carousel-caption {
  color: $color-grey-light;
}

.accordion-button:not(.collapsed) {
  color: $table-header-color;
  background-color: $color-green-light;
  box-shadow: none;
}

.accordion-button:focus {
  box-shadow: none;
  z-index: 0; // issue with react select when option are open
}
.page-item.active .page-link {
  z-index: 0; // issue with react select when option are open
}

.btn {
  &:focus {
    box-shadow: none;
  }
  box-shadow: none;
  &.btn-primary {
    background-color: $color-green-light;
    border-color: $color-green-dark;
    color: $color-grey-dark;
    &:hover {
      background-color: $color-green-dark;
    }
  }
}

.actions-buttons .btn{
  padding: 0;
  padding: 0;
}

.form-control,
.form-control:focus {
  text-decoration: none!important;
  box-shadow: none!important;
}

.input-group {
  flex-wrap: unset;
  .Field
  {
    width: 100%;    
    input {
      width: 100%;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}