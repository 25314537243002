@import './variables';

@import url('https://fonts.googleapis.com/css2?family=Hanalei&display=swap');

html {
  position: relative;
  min-height: 100%;
  color: $color-grey-light;
}

#app-wrapper {
  position: relative;
  /* Margin bottom by footer height */
  display: flex;
  align-items: stretch;
  padding-bottom: 60px;
  #app-content {
    width: 100%;
    min-height: 100vh;
    transition: all 0.3s;
    #app-header {
      height: 40px;
      color: var(--bs-white);
      background-color: $color-grey-dark;
      margin-bottom: 20px;
      .app-logo {
        height: 40px;
        pointer-events: none;
      }
      .nav-link, .btn-link {
        color:var(--bs-white);
        text-decoration: none;
        box-shadow: none;
      }
      .main-menu-toggle {
        color: $color-green-light;
      }
    }
  }
  #app-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    /* Set the fixed height of the footer here */
    height: 60px;
    line-height: 60px; /* Vertically center the text there */
    background-color: $color-grey-dark;
    font-size: 80%;
  }
}




.color-2 {
  color: $color-green-dark;
  a {
    color: $color-green-dark;
  }
}
.color-1 {
  color: $color-green-light;
  a {
    color: $color-green-light;
  }
}

.bg-color-green-light {
  background-color: $color-green-light;
}

th.datetime,
td.datetime {
  width: 150px;
  text-align: center;
}
th.date,
td.date {
  width: 50px;
  text-align: center;
}

th.actions {
  text-align: center;
}

td.actions {
  text-align: right;
}

th.ofOn,
td.ofOn {
  max-width: 30px;
  text-align: center;
}

.usage-chart {
  max-width: 250px;
}
// .circular-progress-bar-chart {
//   width: 130px;
// }
.accordion-toggle-btn button {
  box-shadow: none;
  color: $color-green-dark;
  text-decoration: none;
  &:hover {
    color: $color-green-dark;
    box-shadow: none;
  }
}

.account-subscribers {
  min-height: 200px;
}

.field-height {
  line-height: 36px;;
}
.items-list {
  .list-pagination {
    ul {
      margin-bottom: 0;
    }
  }
}

.non-editable-field {
  line-height: 38px;
}

.captcha-code.input-group-text {
  padding-top: 0;
  padding-bottom: 0;
}

.btn-min-width {
  min-width: 90px;
}
